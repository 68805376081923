<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({}),
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Prompt', sans-serif;
  text-decoration: none;
}
.v-application--wrap {
  background: #23252a !important;
}
.content {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  overflow: auto;
}
.phone-user-input input::-webkit-outer-spin-button,
.phone-user-input input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.phone-user-input input[type='number'] {
  -moz-appearance: textfield !important;
}
label {
  color: #fff;
}

/* ************Table************** */
.wrapper-table {
  width: 100%;
  position: relative;
  overflow: auto;
}
.table-table {
  width: 100%;
  border-collapse: collapse;
}
.table-head {
  height: 50px;
}
.table-head th {
  font-weight: 500;
  font-size: 15px !important;
  color: #fff;
  background: #fff;
}
.table-body {
  background: #fff;
}
.table-body:nth-child(odd) {
  background: #f0f2f5;
}
.table-head th {
  background: #000 !important;
}
.table-body td,
.table-head th {
  padding: 8px;
  text-align: left;
  font-size: 16px;
  white-space: nowrap;
}
.table-body td {
  padding: 5px 8px;
}
/* *******************Table********************* */
</style>
