import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logisticsName: ['Kerry', 'EMS', 'J&T']
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getLogisticsName(state) {
      return state.logisticsName
    }
  }
})
