import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: () => import('../views/Tracking.vue'),
  },
  {
    path: '/management/login',
    name: 'Login',
    component: () => import('../views/admin/Login.vue'),
  },
  {
    path: '/management/register',
    name: 'Register',
    component: () => import('../views/admin/Register.vue'),
  },
  {
    path: '/management/add-tracking',
    name: 'AddTracking',
    component: () => import('../views/admin/AddTracking.vue'),
  },
  {
    path: '/management/tracking-list',
    name: 'TrackingList',
    component: () => import('../views/admin/TrackingList.vue'),
  },
  {
    path: '/management/products',
    name: 'Products',
    component: () => import('../views/admin/Products.vue'),
  },
  {
    path: '/management/accounts',
    name: 'Accounts',
    component: () => import('../views/admin/Accounts.vue'),
  },
  {
    path: '/management/customer',
    name: 'Customer',
    component: () => import('../views/admin/Customer.vue'),
  },
  {
    path: '/management/add-order',
    name: 'AddOrder',
    component: () => import('../views/admin/AddOrder.vue'),
  },
  {
    path: '/management/orders',
    name: 'Orders',
    component: () => import('../views/admin/Orders.vue'),
  },
  {
    path: '/management/order/:orderId',
    name: 'OrderDetail',
    component: () => import('../views/admin/OrderDetail.vue'),
  },
  {
    path: '/management/customer-orders',
    name: 'CustomerOrders',
    component: () => import('../views/admin/CustomerOrders.vue'),
  },
  {
    path: '/management/customer-order/:customerId',
    name: 'CustomerOrderDetail',
    component: () => import('../views/admin/CustomerOrderDetail.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/',
    '/tracking',
    '/management/login',
    '/management/register',
  ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem(process.env.VUE_APP_AUTH)

  if (authRequired && !loggedIn) {
    next('management/login')
  } else {
    next()
  }
})

// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.baseURL = 'https://mmtoyshop-api.resolve-solution.net'

export default router
